import jQuery from 'jquery';
import objectFitImages from 'object-fit-images';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'Includes/browser-detect';
import 'Includes/slick';

(function($){

    $(window).on('scroll',function(){
        let posY = $(window).scrollTop();
        let all_height = document.documentElement.scrollHeight;
        let view_height = window.innerHeight;
        let av_height = all_height - view_height;

        if(posY > 10){
            if(av_height <= 500) return true;
            $('body').addClass('fixed');
        }else{
            $('body').removeClass('fixed');
        }
    });

    $(".hamburger").on('click',function(){
        let is_active = $(this).hasClass('is-active');
        let $_label = $(".header__hamburgers_label");
        let $spmenu = $(".spmenu");
        if(is_active){
            $(this).removeClass('is-active');
            $_label.removeClass('is-active').text('MENU');
            $spmenu.removeClass('active');
            clearAllBodyScrollLocks();
        }else{
            $(this).addClass('is-active');
            $_label.addClass('is-active').text('CLOSE');
            $spmenu.addClass('active');
            disableBodyScroll($spmenu);
        }
    });

    if(_uac.isMobile){
        $('.ste_tel_link').each(function (index, value) { 
            let strTel = $(this).text(); 
			let strTel2 = strTel.replace(/[^0-9]/ig, ""); // removes non numeric characters
			var telAnchor = $("<a>").attr("href", "tel:"+strTel2).text(strTel);
            $(this).html(telAnchor);
        });
    }

    objectFitImages('img.ofi');

    //URLのハッシュ値を取得
	var urlHash = location.hash;
	//ハッシュ値があればページ内スクロール
	if(urlHash) {
	  //スクロールを0に戻す
	  $('body,html').stop().scrollTop(0);
	  setTimeout(function () {
		//ロード時の処理を待ち、時間差でスクロール実行
		scrollToAnker(urlHash) ;
	  }, 1500);
	}

	$('a[href^="#"] , a[href^="/#"]').on('click',function(){
		if($(this).is('.header__mobile_nav,.mm-next,.mm-btn')){
			return true;
		}
		var href= $(this).attr("href").replace(/\//g, '');
		var target = $(href == "#" || href == "" ? 'html' : href);
		if(target.length){
			scrollToAnker(target);
			return false;
		}
    });

    function scrollToAnker(hash) {
		var target = $(hash);
		var position = target.offset().top;
		$('body,html').stop().animate({scrollTop:position}, 500);
	}

    $('.home_bnr__box').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					slidesToShow: 3,
                    slidesToScroll: 1,
				}
			}
		]
    });

    //トップニュース切り替え
    let $newsbox = $(".home_news__content");
    let $newslink = $(".home_news__tablink");
    let $base_json_url = "/e/json_";
    let $default_json_url = "/e/json_all/";
    let $archive_btn = $(".news_archive_link");
    let $arhicve_base_uri = '/e/';
    let $tab_btn = $(".home_news__tablink");

    $.getJSON($default_json_url,function(data){
        let news_html = set_news_html(data);
        $newsbox.html(news_html).addClass('loaded');
    });

    $newslink.on('click',function(){
        $newsbox.html('').removeClass('loaded');
        let jsontype = $(this).data('filter');
        let jsonurl = $base_json_url + jsontype + '/';
        let $arhicve_uri = jsontype == 'all' ? $arhicve_base_uri + 'news/' : $arhicve_base_uri + 'news-cat/' + jsontype + '/';
        $tab_btn.removeClass('active');
        let $that = $(this);
        $.getJSON(jsonurl,function(data){
            let news_html = set_news_html(data);
            $newsbox.html(news_html).addClass('loaded');
            $archive_btn.attr('href',$arhicve_uri);
            $that.addClass('active');
        });
    });

    function set_news_html(json){
        let html = '<div class="newslist"> \
                        <dl class="newslist__box">';
        $.each(json,function(key,val){
            let blank = val.target == 'blank' ? '<i class="fa fa-window-restore"></i>' : '';
            let tblank = val.target == 'blank' ? 'target="_blank"' : '';
            html += '<dt class="newslist__header">'+ val.date +'</dt> \
                    <dd class="newslist__item"> \
                        <a href="'+ val.permalink +'" class="newslist__cat '+ val.catslug +'"><span>'+ val.cat +'</span></a> \
                        <a href="'+ val.permalink +'" '+ tblank +' class="newslist__link"><span>'+ val.title +'</span>'+ blank +'</a> \
                    </dd>';
        });
        html += "</dl> \
            </div>";

        return html;
    }

    //トップへ戻る
    let topBtn=$('.pagetop');
    topBtn.hide();

    let scrollHeight = $(document).height(); //ドキュメントの高さ 
    let footHeight = $(".footer").innerHeight(); //footerの高さ（＝止めたい位置）

    $(window).on('scroll',function(){
        let scrollPosition = $(window).height() + $(window).scrollTop(); //現在地 

        if($(this).scrollTop()>100){
            topBtn.fadeIn();
        }else{
            topBtn.fadeOut();
        }

        if ( scrollHeight - scrollPosition  <= footHeight ) {
            topBtn.removeClass('fixed');
        }else{
            topBtn.addClass('fixed');
        }
    });
    // ◇ボタンをクリックしたら、スクロールして上に戻る
    // topBtn.click(function(){
    //   $('body,html').animate({
    //   scrollTop: 0},500);
    //   return false;
    // });
})(jQuery);